import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import CircularBuffer from "../utils/CircularBuffer";
import Timer from "../utils/Timer";

import "./aboutCarousel.css";

interface State {
  element: any
  index: number
}

const Defaults = {
  TRANSITION_TIME: 7000
}

export default class AboutCarousel extends React.Component<any, State> {
  elements: CircularBuffer<any>;
  timer: Timer;

  constructor(props: any) {
    super(props);

    const listOfChildren: any[] = React.Children.toArray(this.props.children)

    this.elements = new CircularBuffer(listOfChildren);
    const { element, index } = this.elements.get();

    this.state = {
      element: element,
      index: index
    }

    this.timer = new Timer(() => { this.changeElementBy(1) }, Defaults.TRANSITION_TIME)
  }

  componentDidMount() {
    this.timer.start()
  }

  changeElementBy(relativeMovement: number) {
    const { element, index } = this.elements.getElementAfterMovingCursorBy(
      relativeMovement
    )

    this.setState({ element: element, index: index })

    this.timer.start()
  }

  render() {
    return (
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={this.state.index}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="animated-element-container">
            <div className="animated-element">
              {this.state.element}
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    );
  }
}
