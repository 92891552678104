import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Navigation from './Navigation';
import Settings from '../utils/Settings';

import './Hero.css'
import RoundButton from './RoundButton';
import FaIconLinkedin from './icons/FaIconLinkedin';

interface Props { }

interface State { }

export default class Hero extends React.Component<Props, State> {
  render() {
    return (
      <>
        <Navigation variant='dark' highlightHome className="imageContainer">

          <Container className="p-0" fluid style={{
            height: "100vh",
            maxHeight: "100vh"
          }}>
            <Row className="justify-content-center align-items-center" style={{ height: "90%" }}>
              <Col className="justify-content-center align-items-center">
                <>
                  <div className="d-none d-sm-block">
                    <div className="text-center">
                      <h1 className="headerText text-white">{Settings.slogan}</h1>
                      <h5 className="subheaderText text-white">{Settings.subslogan}</h5>
                      <div className="mt-5">
                        <RoundButton dark href={Settings.linkedInLink}>Find me on Linked<span style={{ marginLeft: ".1em" }}><FaIconLinkedin /></span></RoundButton>
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-sm-none p-2">
                    <div className="text-center">
                      <h1 className="headerTextSmall text-white">{Settings.slogan}</h1>
                      <h5 className="subheaderTextSmall text-white">{Settings.subslogan}</h5>
                      <div className="mt-4">
                        <RoundButton dark href={Settings.linkedInLink}>Find me on Linked<span style={{ marginLeft: ".1em" }}><FaIconLinkedin /></span></RoundButton>
                      </div>
                    </div>
                  </div>
                </>
              </Col>
            </Row>

          </Container>
        </Navigation>

      </>
    );
  }
}


