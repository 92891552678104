export default class CircularBuffer<T> {
  elements: T[];
  cursor: number;
  constructor(elements: T[]) {
    this.elements = elements;
    this.cursor = 0;
  }
  get(): {
    element: T;
    index: number;
  } {
    return this.getElementAfterMovingCursorBy(0);
  }
  next(): {
    element: T;
    index: number;
  } {
    return this.getElementAfterMovingCursorBy(1);
  }
  previous(): {
    element: T;
    index: number;
  } {
    return this.getElementAfterMovingCursorBy(-1);
  }
  getElementAfterMovingCursorBy(relativeMovement: number): {
    element: T;
    index: number;
  } {
    this.moveCursorBy(relativeMovement);
    const element = this.elements[this.cursor];
    const index = this.cursor;
    return { element, index };
  }
  moveCursorBy(relativePos: number) {
    this.cursor = (this.cursor + relativePos) % this.elements.length;
    if (this.cursor < 0) {
      this.cursor = this.elements.length + this.cursor;
    }
  }
}
