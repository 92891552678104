import React, { CSSProperties } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Article from './Article';
import Settings from '../utils/Settings';
import { ArticleInfo } from '../utils/ArticleInfos';

interface Props {
  articleInfos: ArticleInfo[]
}

interface State { }

const Defaults = {
  TITLE: "Articles",
}

export default class Articles extends React.Component<Props, State> {
  private renderArticleCol(article: ArticleInfo) {
    const { slug, title, short } = article
    const link = Settings.addBasename(slug)
    const imageSrc = Settings.addBasename(slug, Settings.defaultSquareThumbnail.name)

    return (
      <Col className="col-8 col-sm-6 col-md-4 col-lg-3 mb-5 text-center">
        <Article slug={slug} title={title} text={short} link={link} imageSrc={imageSrc} />
      </Col>
    )
  }

  render() {
    const renderedArticles = this.props.articleInfos.map(articleInfo => this.renderArticleCol(articleInfo))
    const description = Settings.articlesDescription
    const title = Defaults.TITLE

    return (
      <>
        <div style={{ background: "linear-gradient(to bottom, rgba(90, 110, 109, 0.23), rgba(255, 255, 255, 1.0) 80%)" }} id="articles">
          <Container style={{ paddingTop: 50 }}>
            <Row className="mt-5">
              <Col className="col-12 col-sm-10 col-md-8 col-lg-6">
                <>
                  <div className="d-none d-sm-block">
                    <h1 className="header text-primary" style={Styles.headerText}>{title}</h1>
                    <p style={Styles.text}>{description}</p>
                  </div>
                  <div className="d-block d-sm-none">
                    <h2 className="header text-primary" style={Styles.headerText}>{title}</h2>
                    <p style={Styles.text}>{description}</p>
                  </div>
                </>

              </Col>
            </Row>
          </Container>
        </div>
        <Container style={{ paddingTop: 50, paddingBottom: 50 }}>
          <Row className="mt-5 justify-content-center">
            {renderedArticles}
          </Row>
        </Container>
      </>
    );
  }
}

const Styles: { [id: string]: CSSProperties; } = {
  header: {
    background: "linear-gradient(rgba(90, 110, 109, 0.13), rgba(255, 255, 255, 0.0))",
    minHeight: "20vh"
  },
  button: {
    borderRadius: "20px",
    paddingTop: ".1rem",
    paddingBottom: ".25rem",
    paddingLeft: "1em",
    paddingRight: "1em"
  },
  container: {
    marginBottom: "10vh",
  },
  headerText: {
    fontFamily: 'Asap',
    fontWeight: 700,
    lineHeight: "1.05em",
  },
  articleText: {
    fontFamily: 'Asap',
    fontWeight: 600,
    lineHeight: "1.05em",
    opacity: 0.7,
    color: 'black',
  },
  text: {
    lineHeight: "1.05em",
    opacity: 0.7,
    color: 'black',
  },
}
