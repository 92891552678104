import React, { CSSProperties } from 'react';

import RoundButton from './RoundButton';

interface Props {
  slug: string,
  title: string,
  text: string,
  imageSrc: string,
  link: string,
}

interface State { }

export default class Article extends React.Component<Props, State> {
  render() {
    const { link, slug, title, imageSrc, text } = this.props

    return (
      <a href={link} className="text-decoration-none">
        <div key={slug} className="text-center">
          <div className="btn-opacity text-center">
            <img className="pl-2 pr-2 pb-4 img-responsive" alt={title} src={imageSrc} style={Styles.image} />
            <h5 className="header text-decoration-none" style={Styles.articleTitle}>{title}</h5>
            <p className="text-decoration-none" style={Styles.articleText}>{text}</p>
          </div>
          <RoundButton href={link}>More</RoundButton>
        </div>
      </a>
    );
  }
}

const Styles: { [id: string]: CSSProperties; } = {
  articleTitle: {
    fontFamily: 'Asap',
    fontWeight: 700,
    lineHeight: "1.05em",
    opacity: 0.7,
    color: 'black',
  },
  articleText: {
    lineHeight: "1.2em",
    opacity: 0.7,
    color: 'black',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '95%',
  },
}
