import React, { CSSProperties } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import avatarSrc from '../assets/tobias_kaechele_01.png'
import FaIconChevronRight from './icons/FaIconChevronRight'
import AboutCarousel from './AboutCarousel'
import Settings from '../utils/Settings'

interface Props { }

interface State {
  avatarSrc: string
  title: string
  text: string
}

export default class AboutV2 extends React.Component<Props, State> {
  aboutCarousel?: AboutCarousel

  render() {
    return (
      <Container style={{ paddingTop: 150, paddingBottom: 150, overflow: 'hidden' }} id="about">
        <Row style={Styles.imageContainer} className="justify-content-center align-items-center">
          <Col className="col-9 col-sm-8 col-md-4 col-lg-4 col-xl-4 text-center mb-5 mb-md-0 p-xl-4" style={{}}>
            <img src={avatarSrc} alt={Settings.author} style={{ maxWidth: '80%' }} className="" />
          </Col>
          <Col className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 align-items-center" style={{}}>
            <div className="d-flex flex-column p-0" style={{}}>
              <div className="mb-4 d-none d-sm-block">
                <h1 className="header text-primary" style={Styles.headerText}>
                  {Settings.aboutTitle}
                </h1>
              </div>
              <div className="mb-4 d-block d-sm-none">
                <h2 className="header text-primary" style={Styles.headerText}>
                  {Settings.aboutTitle}
                </h2>
              </div>
              <div className="d-flex flex-row align-items-center" style={{ height: '3em' }}>
                <AboutCarousel ref={comp => (this.aboutCarousel = comp || undefined)}>
                  {
                    Settings.aboutTexts.map((text: string, index: number) => (
                      <>
                        <div className="d-none d-sm-block">
                          <p key={index} style={Styles.text}>
                            <strong>{text.split('|')[0]}</strong>{text.split('|')[1]}
                          </p>
                        </div>
                        <div className="d-block d-sm-none">
                          <p key={index} style={Styles.textSmall}>
                            <strong>{text.split('|')[0]}</strong>{text.split('|')[1]}
                          </p>
                        </div>
                      </>
                    ))
                  }
                </AboutCarousel>

                <button
                  onClick={() => {
                    if (this.aboutCarousel) {
                      this.aboutCarousel.changeElementBy(1)
                    }
                  }}
                  className="btn ml-auto pl-4"
                  style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none' }}
                >
                  <FaIconChevronRight size="2x" color="rgba(0,0,0,0.2)" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

const Styles: { [id: string]: CSSProperties } = {
  imageContainer: {},
  button: {
    borderRadius: '20px',
    paddingTop: '.1rem',
    paddingBottom: '.25rem',
    paddingLeft: '1em',
    paddingRight: '1em'
  },
  container: {
    marginBottom: '10vh'
  },
  headerText: {
    fontFamily: 'Asap',
    fontWeight: 700,
    lineHeight: '1.05em'
  },
  text: {
    fontSize: '1.2em',
    lineHeight: '1.2em',
    color: 'rgba(0,0,0,0.7)'
  },
  textSmall: {
    fontSize: '0.9em',
    lineHeight: '1.2em',
    color: 'rgba(0,0,0,0.7)'
  },
}
