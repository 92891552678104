export default class Timer {
  timeoutHandle?: number
  callback: Function
  delay: number

  constructor(callback: Function, delay: number) {
    this.callback = callback
    this.delay = delay
  }

  cancel() {
    if (!this.timeoutHandle) {
      return
    }

    window.clearTimeout(this.timeoutHandle)
    this.timeoutHandle = undefined
  }

  start() {
    this.cancel()

    this.timeoutHandle = window.setTimeout(
      () => { this.callback() },
      this.delay
    )
  }
}
